<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'Supplier',
  components: {},
};
</script>

<style lang="scss" scoped>

</style>
